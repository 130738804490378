
.hero {
  min-height: 100vh; /* Mindesthöhe 100% des sichtbaren Bildschirms */
  display: flex;
  flex-direction: column; /* Inhalte übereinander anordnen */
  background-image: url('./hintergrundSamichlaus_def.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; /* Wichtig für Overlay */
  color: white; /* Weißer Text für Kontrast */
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px; /* Abstand zu den Rändern */
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Schwarze Überlagerung, um das Bild abzudunkeln */
  z-index: 1; /* Unter dem Text */
}

.hero h1 {
  position: relative; /* Damit der Text über dem Overlay liegt */
  font-size: 3.5rem; /* Größere Schriftgröße */
  font-weight: 600; /* Sehr fett für mehr Kontrast */
  color: #ffdf00; /* Goldgelber Farbton */
  text-shadow: 2px 2px 5px black; /* Schwarzer Schatten für Text */
  letter-spacing: 2px; /* Leichte Buchstabenabstände */
  text-transform: uppercase; /* Alle Buchstaben groß für mehr Präsenz */
  z-index: 2; /* Über dem Overlay */
  margin-bottom: 20px;
}

.lead-bold {
  position: relative; /* Text über dem Overlay */
  z-index: 2; /* Über dem Overlay */
  font-weight: 300; /* Dickerer Text */
  font-size: 1.25rem; /* Größere Schriftgröße */
}

.lead-bold a {
  color: #ffdf00; /* Gelbe Links passend zum Thema */
  text-decoration: none;
}
.btn-transparent {
  position: relative; /* Buttons über dem Overlay */
  opacity: 0.85; /* Etwas undurchsichtiger */
  transition: opacity 0.3s ease;
  z-index: 2; /* Über dem Overlay */
}

.btn-transparent:hover {
  opacity: 1; /* Vollständig undurchsichtig, wenn der Mauszeiger darüber schwebt */
}

.contact-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(64, 52, 52, 0.8); /* Transparente dunkle Leiste */
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  z-index: 1000;
}

.contact-bar a {
  color: #ffdf00; /* Gelbe Links passend zum Thema */
  text-decoration: none;
}

.contact-bar a:hover {
  text-decoration: underline;
}

body {
  margin: 0;
  font-family: Arial, sans-serif; /* Standardschriftart */
}

/* Mobile-Optimierungen */
@media only screen and (max-width: 768px) {
  .hero {
    padding: 20px;
  }

  .hero h1 {
    font-size: 2.5rem; /* Kleinere Schriftgröße auf mobilen Geräten */
  }

  .lead-bold {
    font-size: 1.1rem; /* Kleinere Schriftgröße auf mobilen Geräten */
  }

  .btn-transparent {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .contact-bar {
    font-size: 0.85rem;
    padding: 8px;
  }
}